import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import dayjs from "dayjs";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueI18n from "@/libs/i18n";

export default function useProductionRationsList() {
  // Use toast
  const toast = useToast();

  const refProductionRationListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "preview", label: VueI18n.t("view") },

    { key: "rationname", label: VueI18n.t("rationName"), sortable: false },
    {
      key: "addDate",
      label: VueI18n.t("addDate"),
      sortable: true,
      formatter: (val) =>
        `${dayjs(val)
          .locale(`${VueI18n.locale}`)
          .format("DD MMMM YYYY HH:mm:ss")}`,
    },
    {
      key: "tip",
      label: VueI18n.t("type"),
      sortable: true,
      formatter: (value) => VueI18n.t(value.toLowerCase()),
    },
    { key: "delete", label: VueI18n.t("delete") },
  ];
  const perPage = ref(10);
  const totalProductionRations = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refProductionRationListTable.value
      ? refProductionRationListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProductionRations.value,
    };
  });

  const refetchData = () => {
    refProductionRationListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData();
  });

  const fetchProductionRations = async (ctx, callback) => {
    var orders = await store
      .dispatch("rationsModule/fetchProductionRations", {
        tur: router.currentRoute.params.tur,
        plantid: store.state.app.selectedPlantId,
      })

      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("rations") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    return orders;
  };

  const deleteProductionRation = async (ctx) => {
    await store.dispatch("rationsModule/deleteProductionRation", {
      rationid: ctx.id,
      plantid: ctx.plantid,
      tur: ctx.tur,
    });
    refetchData();
  };
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchProductionRations,
    tableColumns,
    perPage,
    currentPage,
    totalProductionRations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProductionRationListTable,
    deleteProductionRation,
    statusFilter,

    refetchData,
  };
}
